import * as React from 'react';

import { useState } from 'react';
import { createContainer } from 'react-tracked';

const KEY = 'state';
const SERIALIZE_KEYS = ['token', 'invitationId', 'scopes'];

function getSessionStorageOrDefault(key, defaultValue) {
  try {
    const stored = sessionStorage.getItem(key);

    if (!stored) {
      return defaultValue;
    }

    return JSON.parse(stored);
  } catch {}

  return defaultValue;
}

const useValue = () => useState(getSessionStorageOrDefault(KEY, {}));

const {
  Provider,
  useTrackedState,
  useUpdate: _useSetState
} = createContainer(useValue);

const useSetState = () => {
  const setState = _useSetState();

  return React.useCallback((state) => {
    setState(state);

    let _state = state;

    if (typeof _state === 'function') {
      _state = _state({});
    }

    try {
      let stored = {};

      try {
        stored = JSON.parse(sessionStorage.getItem(KEY)) || {};
      } catch {
      }

      for (const key of SERIALIZE_KEYS) {
        if (_state[key]) {
          stored[key] = _state[key];
        }
      }

      sessionStorage.setItem(KEY, JSON.stringify(stored))
    } catch { }
  }, [setState],
  );
};

export { Provider, useTrackedState, useSetState };
