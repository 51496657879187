import * as React from 'react';

import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import CameraIcon from '@mui/icons-material/Camera';

import { QrReader } from 'react-qr-reader';

import { useSetState } from "common/store";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import queryString from 'query-string';

import NormalizeApiUrl from "common/normalize-api-url";

const INVITATION_CODE_REGEX = /^(?=.*[0-9])(?=.*[a-zA-Z])(?:[a-zA-Z0-9]{6})$/
const PHONE_REGEX = /^(?:(?:\+48)?(?: *\d{3}){3}|(?: *\d{3}){3})$/

const apiUrl = NormalizeApiUrl(process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL);

export default function Login() {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [helperText, setHelperText] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [useCamera, setUseCamera] = React.useState(false);

  const phoneInput = React.useRef();

  const setState = useSetState();

  const params = useParams();

  const navigate = useNavigate();

  const { search } = useLocation();

  const query = React.useMemo(() => new URLSearchParams(search), [search]);

  const handleError = React.useCallback((response, text = "") => {
    if (response && (response.status === 401 || response.status === 403)) {
      setHelperText('Błędny numer telefonu, skontaktuj się z nami');

    } else {
      if (text) {
        setHelperText(text);

      } else {
        setHelperText('Wystąpił błąd, spróbuj ponownie później');
      }
    }

    setError(true);
    setLoading(false);

    if (phoneInput.current) {
      phoneInput.current.focus();
      phoneInput.current.select();
    }
  }, [setHelperText, setError, setLoading, phoneInput]);

  const performLogin = React.useCallback(async (e) => {
    if (e) {
      e.preventDefault();
    }

    if (!phone || (!phone.match(INVITATION_CODE_REGEX) && !phone.match(PHONE_REGEX))) {
      return handleError(null, "Nieprawidłowy format");
    }

    setLoading(true);

    try {
      let url = null;

      if (phone.match(INVITATION_CODE_REGEX)) {
        url = new URL(`${apiUrl}auth/by-code/${encodeURIComponent(phone)}`);

      } else if (phone.match(PHONE_REGEX)) {
        url = new URL(`${apiUrl}auth/by-phone/${encodeURIComponent(phone)}`);
      }

      if (!url) {
        return handleError();
      }

      let authorizationKey = query.get('authkey');

      if (authorizationKey) {
        url.searchParams.set('authkey', authorizationKey);
      }

      let response = await fetch(url);

      if (!response.ok) {
        return handleError(response);
      }

      response = await response.json();

      if (!response || !response.token) {
        return handleError();
      }

      const token = response.token;

      const bearer = `Bearer ${token}`;

      response = await fetch(`${apiUrl}auth/`, {
        headers: { 'Authorization': bearer }
      });

      if (!response.ok) {
        return handleError(response);
      }

      response = await response.json();

      if (!response || !response.scopes) {
        return handleError();
      }

      const invitationId = response.invitation_id;
      const scopes = response.scopes.split(' ');

      setState(state => ({ ...state, token: token, invitationId: invitationId, scopes: scopes }));

      navigate('/');
    } catch {
      handleError();
    }
  }, [phone, query, setState, navigate, handleError]);

  function handleInput(e) {
    if (error) {
      setHelperText('');
      setError(false);
    }

    setPhone(e.target.value);
  }

  function handleBlur(e) {
    if (error) {
      setHelperText('');
      setError(false);
    }
  }

  const toggleCamera = React.useCallback(() => {
    setUseCamera(useCamera => !useCamera);
  }, [setUseCamera]);

  const parseQRCode = React.useCallback((result, error) => {
    if (!result || !result.text || error) {
      return;
    }

    try {
      const query = queryString.parseUrl(result.text);
      const phone = query.url.substring(query.url.lastIndexOf('/') + 1);

      if (phone) {
        if (phone.match(INVITATION_CODE_REGEX)) {
          setPhone(phone);
          setUseCamera(false);

        } else if (phone.match(PHONE_REGEX)) {
          setPhone(phone);
          setUseCamera(false);
        }
      }
    } catch {
      /* ignored */
    }
  }, [setPhone, setUseCamera]);

  React.useEffect(() => {
    if (loading || error) {
        return;
    }

    if (phone.match(INVITATION_CODE_REGEX) || phone.match(PHONE_REGEX)) {
      setLoading(true);
      performLogin();
    }
  }, [phone, loading, error, setLoading, performLogin]);

  React.useEffect(() => {
    if (params.code) {
      setPhone(params.code);

    } else if (params.phone) {
      setPhone(params.phone);

    } else {
      setPhone('');
    }
  }, [setPhone, params]);

  return (
    <Stack direction='column' spacing={2}>
      <Typography variant='h6' component='h6'>
        Podaj numer telefonu, żeby kontynuować
      </Typography>
      <Stack direction={{ xs: 'column', sm: 'column', md: 'row' }} spacing={2}>
        <TextField inputRef={phoneInput} id='phone' label='Numer telefonu' variant='outlined' fullWidth value={phone} onInput={handleInput} onBlur={handleBlur} inputProps={{ pattern: '[a-zA-Z0-9+\s]+', autoCapitalize: 'none', autoCorrect: 'off' }} helperText={helperText} error={error} disabled={loading} autoFocus required/>
        <Divider sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }} orientation='vertical' flexItem />
        <LoadingButton
          loadingPosition='end'
          endIcon={<DoubleArrowIcon />}
          variant='text'
          loading={loading}
          disabled={!phone}
          onClick={performLogin}
        >Dalej</LoadingButton>
      </Stack>
      <Button
        variant='contained'
        endIcon={<CameraIcon />}
        disabled={loading}
        onClick={toggleCamera}
      >Skanuj kod QR zaproszenia</Button>
      { useCamera && (
        <Stack sx={{ display: 'flex' }} alignItems='center' justifyContent='center'>
          <Typography variant='overline' justifyContent='center' alignItems='center'>Problemy? Spróbuj użyć aplikacji Aparat.</Typography>
          <Box style={{ width: '100%' }}>
            <QrReader
              onResult={parseQRCode}
              constraints={{
                facingMode: 'environment',
                focusMode: 'manual',
                focusDistance: 0.2
              }}/>
          </Box>
        </Stack>)}
    </Stack>
  );
}
