function NormalizeApiUrl(url) {
   var l = document.createElement("a");

   l.href = url;

   url = `${l.protocol}//${l.hostname}`

   if (l.port) {
     url = `${url}:${l.port}`
   }

   if (l.pathname) {
     url = `${url}${l.pathname}`
   }

   if (!url.endsWith('/')) {
     url += '/';
   }

   return url;
}

export default NormalizeApiUrl;
