import * as React from 'react';

import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';

import { Provider } from "common/store";
import { Theme } from "common/theme";

import { ThemeProvider } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import TopBar from 'components/top-bar';
import Home from 'components/home';
import Login from 'components/login';
import Challenge from 'components/challenge';
import ThankYou from 'components/thank-you';

import Invitations from 'components/invitations';
import InvitationIndex from 'components/invitation/index';

import GalleryIndex from 'components/gallery/index';

export default function App() {
  return (
    <Provider>
      <ThemeProvider theme={Theme}>
        <BrowserRouter>
          <Routes>
            <Route element={
                <Container maxWidth="sm">
                  <Box sx={{ my: 4 }}>
                    <TopBar showBackButton={false}/>
                    <Outlet />
                  </Box>
                </Container>
              }>
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/login/:code" element={<Login />} />
              <Route path="/thank-you" element={<ThankYou />} />
              <Route path="/invitations" element={<Invitations />} />
            </Route>
            <Route element={
                <Container maxWidth="sm">
                  <Box sx={{ my: 4 }}>
                    <TopBar showBackButton={true}/>
                    <Outlet />
                  </Box>
                </Container>
              }>
              <Route path="/challenge" element={<Challenge />} />
              <Route path="/invitation/:id" element={<InvitationIndex />} />
            </Route>
            <Route element={
                <Container maxWidth="lg">
                  <Box height="100dvh" display="flex" flexDirection="column" sx={{ boxSizing: 'border-box', py: 2 }}>
                    <TopBar variant="h6" showBackButton={true}/>
                    <Box height="100%" display="flex" sx={{ justifyContent: 'center', alignItems: 'center'}}>
                      <Outlet />
                    </Box>
                  </Box>
                </Container>
              }>
              <Route path="/gallery/:id" element={<GalleryIndex />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
}
