import ContentLoader from 'react-content-loader'

const FormLoader = props => {
  return (
    <ContentLoader
      width="100%"
      height="319"
      viewBox="0 0 445 319"
      preserveAspectRatio="none"
      speed={0.8}
      interval={0.1}
      {...props}
    >
      <rect x="0" y="4" rx="3" ry="3" width="65" height="28" />
      <rect x="0" y="46" rx="3" ry="3" width="216" height="60" />
      <rect x="229" y="46" rx="3" ry="3" width="216" height="60" />
      <rect x="0" y="120" rx="3" ry="3" width="216" height="40" />
      <rect x="229" y="120" rx="3" ry="3" width="216" height="40" />
      <rect x="0" y="176" rx="3" ry="3" width="445" height="33" />
      <rect x="0" y="224" rx="3" ry="3" width="445" height="40" />
      <rect x="90" y="286" rx="3" ry="3" width="123" height="33" />
      <rect x="235" y="286" rx="3" ry="3" width="123" height="33" />
    </ContentLoader>
  )
}

export default FormLoader;
