import React from 'react';

import { string } from 'prop-types';

const Download = React.memo(({
  url
}) => {
  return (
    <a className="image-gallery-icon image-gallery-download-button" href={url} target="_blank" rel="noreferrer" aria-label="Download Photo" download>
      <svg className="image-gallery-svg"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round">
        <polygon points="3 5 21 5 12 21 3 5" />;
      </svg>
    </a>
  );
});

Download.displayName = 'Download';

Download.propTypes = {
  url: string.isRequired,
};

export default Download;
