import * as React from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import { useDictionary } from 'common/dictionary';
import { useNavigate } from "react-router-dom";

function TopBar({ variant, showBackButton }) {
  const [ dictionary ] = useDictionary();

  const navigate = useNavigate();

  variant = variant || 'h4'

  return (
    <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ my: 1 }}>
      <Typography variant={variant} component='h1'>
        {dictionary.caption}
      </Typography>
      {showBackButton && (
        <Link underline='none' href='#' variant='button' onClick={() => navigate(-1)}>
          Powrót
        </Link>
      )}
    </Stack>
  );
}

export default TopBar;
