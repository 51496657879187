import * as React from 'react';

import NormalizeApiUrl from 'common/normalize-api-url';

import ImageGallery from 'react-image-gallery';
import Download from './download';

import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { useTrackedState } from 'common/store';

const apiUrl = NormalizeApiUrl(process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL);

export default function GalleryIndex() {
  const state = useTrackedState();

  const navigate = useNavigate();

  const params = useParams();

  const [photos, setImages] = React.useState([]);
  const [photoIndex, setPhotoIndex] = React.useState([]);
  const [idleCallback, setIdleCallback] = React.useState();

  const [currentIndex, setCurrentIndex] = React.useState(0);

  const handleLoadError = React.useCallback((response) => {
    if (response && (response.status === 401 || response.status === 403)) {
      navigate('/login');
    }

    return false;
  }, [navigate]);

  const renderCustomControls = React.useCallback(() => {
    if (currentIndex >= photos.length) {
      return;
    }

    let url = photos[currentIndex]["_original"];

    return (
      <Download url={url} />
    );
  }, [photos, currentIndex]);

  const onSlide = React.useCallback((_currentIndex) => {
    setCurrentIndex(_currentIndex);
  }, [setCurrentIndex]);

  const fetchGalleryData = React.useCallback(async () => {
    if (!state.token) {
      return navigate('/');
    }

    const bearer = `Bearer ${state.token}`;

    let response;

    try {
      response = await fetch(`${apiUrl}photo-indices/${encodeURIComponent(params.id)}`, {
        headers: { 'Authorization': bearer }
      });
    } catch {
      return handleLoadError();
    }

    if (!response.ok) {
      return handleLoadError(response);
    }

    response = await response.json();

    if (!response || !response.photo_index) {
      return handleLoadError();
    }

    setPhotoIndex(response.photo_index);

    const photos = response.photo_index.photos;

    let _photos = []

    for (const photo of photos) {
      _photos.push({
        "_id": photo.id,
        "_original": `${apiUrl}${photo.download.original.url}`,
        "original": `${apiUrl}${photo.download.hd.url}`,
        "thumbnail": `${apiUrl}${photo.download.thumbnail.url}`,
        "description": photo.description || ""
      })
    }

    _photos.sort((a, b) => a._id - b._id);

    setImages(_photos);
    setIdleCallback(null);

    return true;
  }, [navigate, setImages, params, state.token, handleLoadError, setIdleCallback]);

  const onImageError = React.useCallback((e) => {
    if (idleCallback) {
      return;
    }

    setIdleCallback(window.requestIdleCallback(fetchGalleryData, { timeout: 100 }));
  }, [fetchGalleryData, setIdleCallback]);

  React.useEffect(() => {
    if (idleCallback) {
      return;
    }

    setIdleCallback(window.requestIdleCallback(fetchGalleryData, { timeout: 100 }));
  }, [fetchGalleryData, setIdleCallback]);

  return (
    <ImageGallery items={photos} lazyLoad={true} showThumbnails={false} showIndex={true} renderCustomControls={renderCustomControls} onSlide={onSlide} onImageError={onImageError} />
  );
}
