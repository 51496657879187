import * as React from 'react';

import { useTrackedState } from 'common/store';
import { useNavigate } from 'react-router-dom';

import { useInvitation } from 'common/invitation';

const SCOPE_GENERAL_DATA = "general_data";
const SCOPE_INVITATION_DATA = "invitation_data";
const SCOPE_ADMIN = "admin";

export default function Home() {
  const navigate = useNavigate();

  const state = useTrackedState();

  const [ invitation, invitationLoading ] = useInvitation();    

  React.useEffect(() => {
    if (invitationLoading) {
      return;
    }

    if (invitation && invitation.commited) {
      return navigate('/thank-you', { replace: true });
    }

    if (state.token && state.scopes && state.scopes.includes(SCOPE_GENERAL_DATA)) {
      if (state.scopes.includes(SCOPE_ADMIN)) {
        return navigate('/invitations/', { replace: true });

      } else if (state.scopes.includes(SCOPE_INVITATION_DATA)) {
        const invitationId = state.invitationId;

        return navigate(`/invitation/${invitationId}`, { replace: true });

      } else {
        return navigate('/challenge', { replace: true });
      }
    } else {
      return navigate('/login', { replace: true });
    }
  }, [navigate, state, invitation, invitationLoading]);

  return null;
};
