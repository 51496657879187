import React from 'react';
import Link from '@mui/material/Link';

export default function AutoLink(props) {
  let target = null;
  let rel = null;

  if (props.href && props.href.startsWith("http")) {
    target = "_blank";
    rel = "noreferrer";
  }

  return (
    <Link target={target} rel={rel} {...props}></Link>
  );
}
