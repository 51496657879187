import * as React from 'react';

import NormalizeApiUrl from 'common/normalize-api-url';

import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles } from '@mui/styles';

import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';

import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Fab from '@mui/material/Fab';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';

import SaveIcon from '@mui/icons-material/Save';

import FormLoader from 'components/form-loader';

import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { useForm, Controller } from 'react-hook-form';

import { useTrackedState, useSetState } from 'common/store';

const apiUrl = NormalizeApiUrl(process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL);

const useStyles = makeStyles(theme => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

const SCOPE_ADMIN = "admin";

export default function InvitationIndex() {
  const { getValues, formState, handleSubmit, setValue, control, reset, resetField, setError } = useForm();

  const state = useTrackedState();
  const setState = useSetState();

  const navigate = useNavigate();

  const params = useParams();

  const [isAdmin, setIsAdmin] = React.useState(false);

  const [saving, setSaving] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  const [globalError, setGlobalError] = React.useState(false);
  const [globalErrorText, setGlobalErrorText] = React.useState('');

  const [guests, _setGuests] = React.useState([]);
  const [surveyEntries, _setSurveyEntries] = React.useState([]);

  const classes = useStyles();

  const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const handleLoadError = React.useCallback((response) => {
    if (response && (response.status === 401 || response.status === 403)) {
      navigate('/login');
    } else {
      setGlobalErrorText('Wystąpił błąd, spróbuj ponownie później');
      setGlobalError(true);
    }

    setLoading(false);

    return false;
  }, [navigate, setLoading, setGlobalError, setGlobalErrorText]);

  const handleSaveError = React.useCallback((response, guestIndex) => {
    if (response && (response.status === 401 || response.status === 403)) {
      navigate('/login');
    } else if (response && (response.status === 422)) {
      setGlobalErrorText('Wystąpił błąd, sprawdź czy pola są wypełnione poprawnie');
      setGlobalError(true);

      response.json().then(response => {
        if (response.errors) {
          for (const [key, value] of Object.entries(response.errors)) {
            setError(`guests.${guestIndex}.${key}`, { message: Array(value).join(', ') });
          }
        } else {
          handleSaveError();
        }
      }, () => handleSaveError());
    } else {
      setGlobalErrorText('Wystąpił błąd, spróbuj ponownie później');
      setGlobalError(true);
    }

    setSaving(false);

    return false;
  }, [navigate, setSaving, setError, setGlobalError, setGlobalErrorText]);

  const setGuests = React.useCallback(guests => {
    guests = [...guests].sort(
      function(a, b) {
        return a['sorting_key'].localeCompare(b['sorting_key'])
     }
    );

    return _setGuests(guests);
  }, [_setGuests]);

  const setSurveyEntries = React.useCallback(surveyEntries => {
    surveyEntries = [...surveyEntries].sort(
      function(a, b) {
        return a.ids.question > b.ids.question ? 1 : -1;
     }
    );

    return _setSurveyEntries(surveyEntries);
  }, [_setSurveyEntries]);

  React.useEffect(() => {
    async function fetchInvitationData() {
      if (!state.token) {
        return navigate('/');
      }

      const bearer = `Bearer ${state.token}`;

      let response;

      try {
        response = await fetch(`${apiUrl}invitations/${encodeURIComponent(params.id)}`, {
          headers: { 'Authorization': bearer }
        });
      } catch {
        return handleLoadError();
      }

      if (!response.ok) {
        return handleLoadError(response);
      }

      response = await response.json();

      if (!response || !response.invitation) {
        return handleLoadError();
      }

      const invitation = response.invitation;

      setGuests(invitation.guests);
      setSurveyEntries(invitation.survey.entries);

      setLoading(false);

      return true;
    }

    setLoading(true);

    window.requestIdleCallback(fetchInvitationData, { timeout: 500 });
  }, [setGuests, setSurveyEntries, setValue, navigate, params, state.token, handleLoadError]);

  React.useEffect(() => {
    reset({ 'guests': guests, 'survey': { 'entries': surveyEntries } });
  }, [guests, surveyEntries, reset]);

  React.useEffect(() => {
    setIsAdmin(state.scopes.includes(SCOPE_ADMIN));
  }, [setIsAdmin, state.scopes]);

  const submitForm = React.useCallback(async (data) => {
    async function saveForm(data) {
      const updatedGuests = [...guests];

      const bearer = `Bearer ${state.token}`;

      for (const [i, guest] of data['guests'].entries()) {
        let id = guest.id;

        delete guest['id'];

        let request = {'guest': guest};

        let response;

        try {
          response = await fetch(`${apiUrl}guests/${id}`, {
            headers: { 'Authorization': bearer, 'Content-Type': 'application/json' },
            method: 'PATCH',
            body: JSON.stringify(request),
          });
        } catch {
          return handleSaveError();
        }

        if (!response.ok) {
          return handleSaveError(response, i);
        }

        response = await response.json();

        if (!response || !response.guest) {
          return handleSaveError();
        }

        updatedGuests[i] = response.guest;
      }

      let request = {'survey': data['survey']};

      let response;

      try {
        response = await fetch(`${apiUrl}invitations/${encodeURIComponent(params.id)}/survey`, {
          headers: { 'Authorization': bearer, 'Content-Type': 'application/json' },
          method: 'PATCH',
          body: JSON.stringify(request)
        });
      } catch {
        return handleSaveError();
      }

      if (!response.ok) {
        return handleSaveError(response);
      }

      response = await response.json();

      if (!response || !response.survey) {
        return handleSaveError();
      }

      const survey = response.survey;

      setGuests(updatedGuests);
      setSurveyEntries(survey.entries);

      return true;
    }

    async function commitForm() {
      const bearer = `Bearer ${state.token}`;

      let response;

      try {
        response = await fetch(`${apiUrl}invitations/${encodeURIComponent(params.id)}/commit`, {
          headers: { 'Authorization': bearer },
          method: 'POST'
        });
      } catch {
        return handleSaveError();
      }

      if (!response.ok) {
        return handleSaveError(response);
      }

      response = await response.json();

      if (!response || !response.invitation) {
        return handleSaveError();
      }

      const invitation = response.invitation;

      if (state.invitationId === invitation.id) {
        setState(state => ({ ...state, invitation: invitation }));
      }

      return true;
    }

    setSaving(true);

    let result;

    if (formState.isDirty) {
      result = await saveForm(data);

      if (!result) {
        return;
      }
    }

    if (!isAdmin) {
      result = await commitForm();

      if (!result) {
        return;
      }
    }

    setSaving(false);

    if (isAdmin) {
      navigate('/');
    } else {
      navigate('/thank-you');
    }
  }, [formState.isDirty, setSaving, navigate, setGuests, setSurveyEntries, state.token, guests, handleSaveError, setState, state.invitationId, params.id, isAdmin]);


  async function updateGuestProperties(key) {
    let changed = false;

    for (const [i, item] of guests.entries()) {
      let value = getValues(`guests.${i}.${key}`);

      if (typeof value === 'undefined') {
        continue;
      }

      if (value !== item[key]) {
        setSaving(true);

        let request = {'guest': {}};

        request['guest'][key] = value;

        const bearer = `Bearer ${state.token}`;

        let response;

        try {
          response = await fetch(`${apiUrl}guests/${item.id}`, {
            headers: { 'Authorization': bearer, 'Content-Type': 'application/json' },
            method: 'PATCH',
            body: JSON.stringify(request),
          });
        } catch {
          return handleSaveError();
        }

        if (!response.ok) {
          return handleSaveError(response);
        }

        response = await response.json();

        if (!response || !response.guest) {
          return handleSaveError();
        }

        const guest = response.guest;

        resetField(`guests.${i}.${key}`, { defaultValue: guest[key] });
        guests[i][key] = guest[key];

        changed = true;
      }
    }

    if (changed) {
      setSaving(false);
    }

    return true;
  }

  if (loading) {
    return (
      <FormLoader/>
    );
  } else {
    return (
      <form>
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={6000} open={globalError} onClose={() => setGlobalError(false)}>
          <Alert variant='filled' severity='error' onClose={() => setGlobalError(false)} sx={{ width: '100%' }}>{globalErrorText}</Alert>
        </Snackbar>
        <Stack spacing={2} divider={<Divider orientation='horizontal' light flexItem />}>
          {guests.map((item, i) => (
            <Stack spacing={2} key={`guests.${i}`}>
              <Typography variant='h6' component='h6'>
                Gość{'\u00A0'}{i+1}{'\u00A0'}
                <Typography display='inline' sx={{ color: 'text.disabled' }}>
                  {
                    {
                      'child': ' (dziecko)',
                      'companion': ' (osoba towarzysząca)',
                    }[item.guest_type]
                  }
                </Typography>
              </Typography>
              <Stack direction='row' spacing={2}>
                 <Controller name={`guests.${i}.name`} control={control} render={({ field: { onChange, value } }) => (
                   <TextField onChange={onChange} value={value || ''} label={'Imię'} variant='outlined' fullWidth />
                 )}/>
                 <Controller name={`guests.${i}.surname`} control={control} render={({ field: { onChange, value } }) => (
                   <TextField onChange={onChange} value={value || ''} label={'Nazwisko'} variant='outlined' fullWidth />
                 )}/>
              </Stack>
             { item.guest_type !== 'child' && (
               <>
                 <Stack direction='row' spacing={2}>
                   <Controller name={`guests.${i}.phone`} control={control} render={({ field: { onChange, value } }) => (
                     <TextField onChange={onChange} value={value || ''} label={'Telefon'} variant='outlined' size='small' inputProps={{ autoCapitalize: 'none' }} autoComplete='tel' helperText={formState.errors.guests?.[i]?.phone?.message} error={!!formState.errors.guests?.[i]?.phone} fullWidth />
                   )}/>
                   <Controller name={`guests.${i}.email`} control={control} render={({ field: { onChange, value } }) => (
                     <TextField onChange={onChange} value={value || ''} label={'E-mail'} placeholder='opcjonalne' variant='outlined' size='small' inputProps={{ autoCapitalize: 'none' }} autoComplete='email' fullWidth />
                   )}/>
                 </Stack>
                 {(i === 0) && (
                   <Box sx={{ display: 'flex' }} justifyContent='center' alignItems='center'>
                     <Typography variant='overline' style={{ wordWrap: 'break-word' }} align='center'>Na telefon i{'\u00A0'}e-mail wyślemy informacje{'\u00A0'}organizacyjne i{'\u00A0'}link do zdjęć</Typography>
                   </Box>
                 )}
                </>
              )}
              <Stack direction='row' spacing={2}>
                 <Controller name={`guests.${i}.comment`} control={control} render={({ field: { onChange, value } }) => (
                   <TextField onChange={onChange} value={value || ''} label={'Komentarz (np. alergie)'} placeholder='opcjonalny' variant='outlined' size='small' fullWidth />
                 )}/>
                 { item.guest_type === 'child' && (
                 <Controller name={`guests.${i}.age`} control={control} render={({ field: { onChange, value } }) => (
                   <div>
                     <FormControl sx={{ minWidth: 80 }} size='small'>
                       <InputLabel id={`guests.${i}.age.label`}>Wiek</InputLabel>
                       <Select labelId={`guests.${i}.age.label`} value={value | ''} onChange={onChange} autoWidth label='Wiek'>
                         <MenuItem value=''> - </MenuItem>
                         <MenuItem value={6}>{'<'} 6 lat</MenuItem>
                         <MenuItem value={12}>6-12 lat</MenuItem>
                         <MenuItem value={18}>{'>'} 12 lat</MenuItem>
                      </Select>
                    </FormControl>
                   </div>
                 )}/>
                 ) }
              </Stack>
              <Stack direction='row' spacing={2} justifyContent='center' alignItems='center'>
                <FormGroup>
                  <FormControlLabel label='Widzimy się?' labelPlacement='start' control={
                    <Controller name={`guests.${i}.present`} control={control} render={({ field: { onChange, value } }) => (
                      <Checkbox onChange={(e) => { onChange(e); updateGuestProperties('present'); }} checked={value || false} />
                    )} />
                  } />
                </FormGroup>
                <FormGroup>
                  <FormControlLabel label='Opcja vege?' labelPlacement='start' control={
                    <Controller name={`guests.${i}.vege`} control={control} render={({ field: { onChange, value } }) => (
                      <Checkbox onChange={(e) => { onChange(e); updateGuestProperties('vege'); }} checked={value || false} />
                    )} />
                  } />
                </FormGroup>
              </Stack>
            </Stack>
          ))}
          {surveyEntries.map((item, i) => (
            <Stack spacing={2} key={`survey.entries.${i}`}>
              {item.type === 'string' && (
                <React.Fragment>
                  <Typography variant='h6' component='h6' gutterBottom>
                    {item.question}
                  </Typography>
                  <Controller name={`survey.entries.${i}.answer`} control={control} render={({ field: { onChange, value } }) => (
                    <TextField onChange={onChange} value={value || ''} label={'Odpowiedź'} variant='outlined' size='small' fullWidth />
                  )} />
                </React.Fragment>
              )}
              {item.type === 'boolean' && (
                <FormGroup>
                  <FormControlLabel label={item.question} control={
                    <Controller name={`survey.entries.${i}.answer`} control={control} render={({ field: { onChange, value } }) => (
                      <Checkbox onChange={onChange} checked={value || false} />
                    )} />
                  } />
                </FormGroup>
              )}
            </Stack>
          ))}

          {	!mobile && (
            <LoadingButton
              variant='contained'
              loadingPosition='end'
              endIcon={<SaveIcon />}
              loading={saving}
              onClick={handleSubmit(submitForm)}
              aria-label='save'
            >Zapisz{ !isAdmin && (<> i zatwierdź</>)}</LoadingButton>
          )}
        </Stack>
        { mobile && (
          <>
            <Box sx={{ width: '100%', height: 40 }}>{'\u00A0'}</Box>
            <Fab
              variant='extended'
              color='primary'
              className={classes.fab}
              disabled={saving}
              onClick={handleSubmit(submitForm)}
              aria-label='save'
            ><SaveIcon sx={{ mr: 1 }} />Zapisz{ !isAdmin && (<> i zatwierdź</>)}
            </Fab>
          </>
        )}
      </form>
    );
  }
}
