import * as React from 'react';

import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

import Markdown from 'components/markdown';

import { useDictionary, DICTIONARY_STATE_ERROR } from 'common/dictionary';

import { useTrackedState, useSetState } from 'common/store';
import { useNavigate } from "react-router-dom";

import NormalizeApiUrl from "common/normalize-api-url";

const RESPONSE_CODE_LENGTH = 6

const apiUrl = NormalizeApiUrl(process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL);

export default function Login() {
  const state = useTrackedState();

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [helperText, setHelperText] = React.useState('');
  const [responseCode, setResponseCode] = React.useState('');
  const [submittedResponseCode, setSubmittedResponseCode] = React.useState('');
  const [initialCodeSent, setInitialCodeSent] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [notification, setNotification] = React.useState('');
  const [abortController, setAbortController] = React.useState('');

  const [ dictionary, dictionaryState ] = useDictionary();

  const responseCodeInput = React.useRef();

  const navigate = useNavigate();

  const setState = useSetState();

  const handleUpgradeError = React.useCallback((response) => {
    if (response && (response.status === 401)) {
      navigate('/login', { replace: true });

    } else if (response && (response.status === 403)) {
      setHelperText('Błędny kod, sprawdź kod lub ponów wysyłkę');
      setError(true);

    } else {
      setHelperText('Wystąpił błąd, spróbuj ponownie później');
      setError(true);
    }

    setLoading(false);

    if (responseCodeInput.current) {
      responseCodeInput.current.focus();
      responseCodeInput.current.select();
    }
  }, [setHelperText, setError, setLoading, responseCodeInput, navigate]);

  const handleChallengeError = React.useCallback((response) => {
    if (response && (response.status === 401 || response.status === 403)) {
      navigate('/login', { replace: true });

    } else if (response && (response.status === 422)) {
      setNotification('Brak numeru w bazie - skontaktuj się z nami!')

    } else if (response && (response.status === 429)) {
      setHelperText('Kod już został wysłany, wprowadź kod lub ponow za chwilę');
      setError(true);

    } else {
      setHelperText('Wystąpił błąd, spróbuj ponownie później');
      setError(true);
    }

    setLoading(false);
  }, [setHelperText, setError, setLoading, navigate]);

  function handleInput(e) {
    setHelperText('');

    if (error) {
      setError(false);
    }

    setResponseCode(e.target.value);
  }

  function handleBlur(e) {
    if (error) {
      setHelperText('');
      setError(false);
    }
  }

  const generateChallenge = React.useCallback(async (e, guestId) => {
    if (e) {
      e.preventDefault();
    }

    setLoading(true);

    try {
      let bearer = `Bearer ${state.token}`;

      let body = {}

      if (guestId) {
        body['guest_id'] = guestId;
      }

      let response;

      try {
        response = await fetch(`${apiUrl}auth/challenge`, {
          headers: { 'Authorization': bearer, 'Content-Type': 'application/json'},
          method: 'POST',
          body: JSON.stringify(body)
        });
      } catch {
        return handleChallengeError();
      }

      setInitialCodeSent(true);

      if (!response.ok) {
        return handleChallengeError(response);
      }
  
      response = await response.json();
      
      if (!response || !response.masked_number || !response.options) {
        return handleChallengeError();
      }
  
      setHelperText(`Wysłano kod na numer ${response.masked_number}`);
      setOptions(response.options)

      setLoading(false);

      if (responseCodeInput.current) {
        setResponseCode('');
        responseCodeInput.current.focus();
      }
    } catch {
      handleChallengeError();
    }
  }, [state.token, setOptions, handleChallengeError, responseCodeInput, setResponseCode, setInitialCodeSent]);

  const submitResponse = React.useCallback(async (e) => {
    if (e) {
      e.preventDefault();
    }

    if (!responseCode) {
      return;
    }

    setLoading(true);
    setSubmittedResponseCode(responseCode);

    if (abortController) {
      abortController.abort();
    }

    try {
      let bearer = `Bearer ${state.token}`;

      let body = { "response": responseCode }

      let response;

      try {
        response = await fetch(`${apiUrl}auth/upgrade`, {
          headers: { 'Authorization': bearer, 'Content-Type': 'application/json'},
          method: 'POST',
          body: JSON.stringify(body)
        });
      } catch {
        return handleUpgradeError();
      }

      if (!response.ok) {
        return handleUpgradeError(response);
      }
  
      response = await response.json();
      
      if (!response || !response.token) {
        return handleUpgradeError();
      }
  
      const token = response.token;
  
      bearer = `Bearer ${token}`;
  
      response = await fetch(`${apiUrl}auth/`, {
        headers: { 'Authorization': bearer }
      });
  
      if (!response.ok) {
        return handleUpgradeError(response);
      }
  
      response = await response.json();
  
      if (!response || !response.scopes) {
        return handleUpgradeError();
      }
  
      const invitationId = response.invitation_id;
      const scopes = response.scopes.split(' ');
  
      setState(state => ({ ...state, token: token, invitationId: invitationId, scopes: scopes }));

      navigate('/', { replace: true });
    } catch {
      handleUpgradeError();
    }
  }, [state.token, responseCode, setState, navigate, handleUpgradeError, setSubmittedResponseCode, abortController]);

  React.useEffect(() => {
    if (responseCode.length === RESPONSE_CODE_LENGTH && responseCode !== submittedResponseCode && !loading && !error) {
      submitResponse();
    }
  }, [responseCode, submittedResponseCode, submitResponse, loading, error]);

  React.useEffect(() => {
    if (!initialCodeSent) {
      generateChallenge();
    }
  }, [generateChallenge, initialCodeSent]);

  React.useEffect(() => {
    if (responseCodeInput.current) {
      responseCodeInput.current.focus();
    }
  }, [responseCodeInput]);

  React.useEffect(() => {
    if (!('OTPCredential' in window)) {
      return;
    }

    if (!options || options.length === 0) {
      return;
    }

    if (abortController) {
      abortController.abort();
    }

    const ac = new AbortController();
    setAbortController(ac);

    try {
      navigator.credentials.get({
        otp: { transport:['sms'] },
        signal: ac.signal
      }).then(otp => {
        setResponseCode(otp.code);
      });
    } catch {
      /* ignored */
    }
  }, [setResponseCode, setAbortController, options]);

  React.useEffect(() => {
    if (dictionaryState === DICTIONARY_STATE_ERROR) {
      navigate('/login', { replace: true });
    }
  }, [dictionaryState, navigate]);

  return (
    <Stack direction='column' spacing={2}>
      <Typography variant='h6' component='h6'>
        Podaj kod SMS, żeby kontynuować
      </Typography>
      <Stack direction={{ xs: 'column', sm: 'column', md: 'row' }} spacing={2}>
        <TextField inputRef={responseCodeInput} id='response-code' label='Kod SMS' variant='outlined' fullWidth value={responseCode} onInput={handleInput} onBlur={handleBlur} inputProps={{ maxLength: RESPONSE_CODE_LENGTH, autoCapitalize: 'none', autoCorrect: 'off', inputMode: 'numeric', pattern: '[0-9]*' }} helperText={helperText} error={error} disabled={loading || !!notification} autoComplete='one-time-code' required />
        { notification && (  
          <Box sx={{ display: { xs: 'flex', sm: 'flex', md: 'none' } }} alignItems='center' justifyContent='center'>
            <Typography variant='overline' justifyContent='center' alignItems='center'>{notification}</Typography>
          </Box>
        )}
        {options && (
          <Stack sx={{ display: { xs: 'flex', sm: 'flex', md: 'none' } }} alignItems='center' justifyContent='center' spacing={0}>
            {options.map((item, i) => (
              <Link key={`option.${i}.xs`} href='#' variant='overline' onClick={() => { generateChallenge(null, item.guest_id); }}>Wyślij ponownie kod na numer {item.masked_number}</Link>
            ))}
          </Stack>
        )}
        <Divider sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }} orientation='vertical' flexItem />
        <LoadingButton
          loadingPosition='end'
          endIcon={<DoubleArrowIcon />}
          variant='text'
          loading={loading}
          disabled={!responseCode || !!notification}
          onClick={submitResponse}
        >Dalej</LoadingButton>
      </Stack>
      { notification && (
        <Box sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }}>
          <Typography variant='overline'>{notification}</Typography>
        </Box>
      )}
      {options && (
        <Stack sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }} spacing={0}>
          {options.map((item, i) => (
            <Link key={`option.${i}.md`} href='#' variant='overline' onClick={() => { generateChallenge(null, item.guest_id); }}>Wyślij ponownie kod na numer {item.masked_number}</Link>
          ))}
        </Stack>
      )}
      <Divider sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }} orientation='horizontal' flexItem />
      { dictionary.contact && (<Markdown>{dictionary.contact}</Markdown>)}
    </Stack>
  );
}
