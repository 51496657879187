import ContentLoader from 'react-content-loader'

const InvitationLoader = props => {
  return (
    <ContentLoader
      width="100%"
      height="82"
      viewBox="0 0 445 82"
      preserveAspectRatio="none"
      speed={0.8}
      interval={0.1}
      {...props}
    >
      <rect x="0" y="4" rx="3" ry="3" width="445" height="82" />
    </ContentLoader>
  )
}

export default InvitationLoader;
