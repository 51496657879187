import * as React from 'react';

import NormalizeApiUrl from 'common/normalize-api-url';

import { useTrackedState, useSetState } from 'common/store';

const STATE_INITIAL = 0;
const STATE_LOADING = 1;
const STATE_ERROR = 2;
const STATE_LOADED = 3;

const SCOPE_INVITATION_DATA = "invitation_data";

const apiUrl = NormalizeApiUrl(process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL);

const useInvitation = () => {
  const state = useTrackedState();
  const setState = useSetState();

  const [ invitation, setInvitation ] = React.useState(state.invitation);
  const [ invitationState, setInvitationState ] = React.useState(
    invitation && invitation.id === state.invitation_id ? STATE_LOADED : STATE_INITIAL);

  React.useEffect(() => {
    async function fetchInvitationData() {
      try {
        if (!state.token || !state.invitationId || !state.scopes || !state.scopes.includes(SCOPE_INVITATION_DATA)) {
          return setInvitationState(STATE_ERROR);
        }

        const bearer = `Bearer ${state.token}`;

        let response = await fetch(`${apiUrl}invitations/${state.invitationId}`, {
          headers: { 'Authorization': bearer }
        });

        if (!response.ok) {
          return setInvitationState(STATE_ERROR);
        }

        response = await response.json();

        if (!response || !response.invitation) {
          return setInvitationState(STATE_ERROR);
        }

        const invitation = response.invitation;

        setInvitation(invitation);
        setInvitationState(STATE_LOADED);
      } catch {
        setInvitationState(STATE_ERROR);
      }
    }

    if (invitationState === STATE_INITIAL || invitationState === STATE_ERROR) {
      setInvitationState(STATE_LOADING);

      fetchInvitationData();
    }
  }, [state.token, state.scopes, state.invitationId, setInvitation, setInvitationState]);

  React.useEffect(() => {
    if (invitation) {
      setState(state => ({ ...state, invitation: invitation }));
    }
  }, [invitation, state, setState]);

  return [ invitation, invitationState === STATE_INITIAL || invitationState === STATE_LOADING ];
};

export { useInvitation };
