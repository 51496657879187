import * as React from 'react';

import NormalizeApiUrl from 'common/normalize-api-url';

import { useTrackedState, useSetState } from 'common/store';

const DICTIONARY_STATE_INITIAL = 0;
const DICTIONARY_STATE_LOADING = 1;
const DICTIONARY_STATE_ERROR = 2;
const DICTIONARY_STATE_LOADED = 3;

const SCOPE_GENERAL_DATA = "general_data";

const apiUrl = NormalizeApiUrl(process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL);

const defaultDictionary = {
  caption: "Ankieta weselna",
  _default: true
};

const useDictionary = () => {
  const state = useTrackedState();
  const setState = useSetState();

  const [ dictionary, setDictionary ] = React.useState(state.dictionary || defaultDictionary);
  const [ dictionaryState, setDictionaryState ] = React.useState(dictionary._default ? DICTIONARY_STATE_INITIAL : DICTIONARY_STATE_LOADED);

  React.useEffect(() => {
    async function fetchDictionaryData() {
      try {
        if (!state.token || !state.scopes || !state.scopes.includes(SCOPE_GENERAL_DATA)) {
          return setDictionaryState(DICTIONARY_STATE_ERROR);
        }

        const bearer = `Bearer ${state.token}`;

        let response = await fetch(`${apiUrl}dictionary`, {
          headers: { 'Authorization': bearer }
        });

        if (!response.ok) {
          return setDictionaryState(DICTIONARY_STATE_ERROR);
        }

        response = await response.json();

        if (!response || !response.dictionary) {
          return setDictionaryState(DICTIONARY_STATE_ERROR);
        }

        const dictionary = response.dictionary;

        setDictionary(dictionary);

        setDictionaryState(DICTIONARY_STATE_LOADED);
      } catch {
        setDictionaryState(DICTIONARY_STATE_ERROR);
      }
    }

    if (dictionaryState === DICTIONARY_STATE_INITIAL) {
      setDictionaryState(DICTIONARY_STATE_LOADING);

      fetchDictionaryData();
    }
  }, [state.token, state.scopes, setDictionary, setDictionaryState, dictionaryState]);

  React.useEffect(() => {
    if (dictionary) {
      setState(state => ({ ...state, dictionary: dictionary }));
    }
  }, [dictionary, state, setState]);

  return [ dictionary, dictionaryState ];
};

export { useDictionary, DICTIONARY_STATE_INITIAL, DICTIONARY_STATE_LOADING, DICTIONARY_STATE_LOADED, DICTIONARY_STATE_ERROR };
