import * as React from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';

import Markdown from 'components/markdown';

import { useTrackedState } from 'common/store';
import { useDictionary, DICTIONARY_STATE_ERROR } from 'common/dictionary';

import { useNavigate } from "react-router-dom";

export default function ThankYou() {
  const state = useTrackedState();

  const navigate = useNavigate();

  const [ dictionary, dictionaryState ] = useDictionary();

  const invitationRoute = `/invitation/${state.invitationId}`

  React.useEffect(() => {
    if (dictionaryState === DICTIONARY_STATE_ERROR) {
      navigate('/login', { replace: true });
    }
  }, [dictionaryState, navigate]);

  return (
    <Stack direction='column' spacing={2}>
      <Typography variant='h6' component='h6'>
       { dictionary.heading }
      </Typography>
      { dictionary.survey_active === 'true' && ( <Typography>
          Jeśli chcesz zmienić lub uzupełnić dane, wróć do ankiety klikając <Link component={RouterLink} to={invitationRoute}>tutaj</Link>.
        </Typography> )}
      { dictionary.summary && ( <Markdown>{dictionary.summary}</Markdown> ) }
    </Stack>
  );
}
