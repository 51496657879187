import * as React from 'react';

import NormalizeApiUrl from 'common/normalize-api-url';

import useMediaQuery from '@mui/material/useMediaQuery';

import { styled } from '@mui/material/styles';

import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import { Link as RouterLink } from 'react-router-dom';

import Switch from '@mui/material/Switch';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';

import InvitationLoader from 'components/invitation-loader';

import { useNavigate } from 'react-router-dom';

import { useTrackedState } from 'common/store';

const apiUrl = NormalizeApiUrl(process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL);

const GUEST_MAIN = 'main';

const CardContentNoPadding = styled(CardContent)(`
  &:last-child {
    padding-bottom: 16px;
  }
`);

export default function Invitations() {
  const state = useTrackedState();

  const navigate = useNavigate();

  const [saving, setSaving] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  const [globalError, setGlobalError] = React.useState(false);
  const [globalErrorText, setGlobalErrorText] = React.useState('');

  const [invitations, _setInvitations] = React.useState([]);

  const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const handleLoadError = React.useCallback((response) => {
    if (response && (response.status === 401 || response.status === 403)) {
      navigate('/login');
    } else {
      setGlobalErrorText('Wystąpił błąd, spróbuj ponownie później');
      setGlobalError(true);
    }

    setLoading(false);

    return false;
  }, [navigate, setLoading, setGlobalError, setGlobalErrorText]);

  const handleSaveError = React.useCallback((response) => {
    if (response && (response.status === 401 || response.status === 403)) {
      navigate('/login');

    } else if (response && (response.status === 422)) {
      setGlobalErrorText('Wystąpił błąd, sprawdź czy pola są wypełnione poprawnie');
      setGlobalError(true);

    } else {
      setGlobalErrorText('Wystąpił błąd, spróbuj ponownie później');
      setGlobalError(true);
    }

    setSaving(false);

    return false;
  }, [navigate, setSaving, setGlobalError, setGlobalErrorText]);

  const setInvitations = React.useCallback(invitations => {
    const _invitations = [...invitations]
      .filter(invitation => invitation.guests.length > 0)
      .sort((a, b) => a['id'] - b['id']);

    return _setInvitations(_invitations);
  }, [_setInvitations]);

  const handleCommitedChanged = React.useCallback( async (event) => {
    let index = event.target.getAttribute('data-index');

    let invitation = invitations[index];
    let id = invitation.id;

    setSaving(true);

    const updatedInvitatons = [...invitations];

    const bearer = `Bearer ${state.token}`;

    let response;

    let uri;

    if (event.target.checked) {
      uri = `${apiUrl}invitations/${id}/commit`;
    } else {
      uri = `${apiUrl}invitations/${id}/reset`;
    }

    try {
      response = await fetch(uri, {
        headers: { 'Authorization': bearer, 'Content-Type': 'application/json' },
        method: 'POST'
      });
    } catch {
      return handleSaveError();
    }

    if (!response.ok) {
      return handleSaveError(response);
    }

    response = await response.json();

    if (!response || !response.invitation) {
      return handleSaveError();
    }

    updatedInvitatons[index] = response.invitation;

    setInvitations(updatedInvitatons);

    setSaving(false);
  }, [setSaving, handleSaveError, setInvitations, invitations, state]);

  const handleIgnoreNotificationsChanged = React.useCallback( async (event) => {
    let index = event.target.getAttribute('data-index');

    let invitation = invitations[index];
    let id = invitation.id;

    setSaving(true);

    const updatedInvitatons = [...invitations];

    let request = {'invitation': {'ignore_notifications': event.target.checked}};

    const bearer = `Bearer ${state.token}`;

    let response;

    try {
      response = await fetch(`${apiUrl}invitations/${id}`, {
        headers: { 'Authorization': bearer, 'Content-Type': 'application/json' },
        method: 'PATCH',
        body: JSON.stringify(request),
      });
    } catch {
      return handleSaveError();
    }

    if (!response.ok) {
      return handleSaveError(response);
    }

    response = await response.json();

    if (!response || !response.invitation) {
      return handleSaveError();
    }

    updatedInvitatons[index] = response.invitation;

    setInvitations(updatedInvitatons);

    setSaving(false);
  }, [setSaving, handleSaveError, setInvitations, invitations, state.token]);

  React.useEffect(() => {
    async function fetchInvitations() {
      if (!state.token) {
        return navigate('/');
      }

      const bearer = `Bearer ${state.token}`;

      let response;

      try {
        response = await fetch(`${apiUrl}invitations`, {
          headers: { 'Authorization': bearer }
        });
      } catch {
        return handleLoadError();
      }

      if (!response.ok) {
        return handleLoadError(response);
      }

      response = await response.json();

      if (!response || !response.invitations) {
        return handleLoadError();
      }

      const _invitations = response.invitations;

      setInvitations(_invitations);

      setLoading(false);

      return true;
    }

    if (!invitations) {
      setLoading(true);
    }

    window.requestIdleCallback(fetchInvitations, { timeout: 500 });
  }, [setInvitations, navigate, state.token, handleLoadError]);

  if (loading) {
    return (
      <InvitationLoader/>
    );
  } else {
    return (
      <div>
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={6000} open={globalError} onClose={() => setGlobalError(false)}>
          <Alert variant='filled' severity='error' onClose={() => setGlobalError(false)} sx={{ width: '100%' }}>{globalErrorText}</Alert>
        </Snackbar>
        <Stack direction='column' spacing={2}>
          {invitations.map((item, i) => (
            <Card key={`invitation.${i}`}>
              <Grid container spacing={2} sx={{ alignItems: 'center' }}>
                <Grid item sm={6} xs={12}>
                  <CardContentNoPadding>
                    {item.guests.filter(guest => guest.guest_type === GUEST_MAIN).map((guest, j) => (
                      <Typography key={`guest.${j}`} variant='button' component='div'>
                        {guest.name} {guest.surname}
                      </Typography>
                    ))}
                  </CardContentNoPadding>
                </Grid>
                <Grid container item sm={6} xs={12} justifyContent={mobile ? 'flext-start' : 'flex-end'}>
                  <CardActions sx={{ width: '100%' }}>
                    <Stack direction='column' spacing={1} divider={<Divider orientation='horizontal' light flexItem />} alignItems={mobile ? 'flext-start' : 'flex-end'} sx={{ width: '100%' }}>
                      <FormGroup>
                        <FormControlLabel disabled={saving} control={<Switch inputProps={{ 'data-index': i }} onChange={handleCommitedChanged} checked={!!item.commited} />} label={<Typography variant='overline'>Ankieta wypełniona</Typography>} />
                        <FormControlLabel disabled={saving} control={<Switch inputProps={{ 'data-index': i }} onChange={handleIgnoreNotificationsChanged} checked={item.ignore_notifications} />} label={<Typography variant='overline'>Ignoruj powiadomienia</Typography>} />
                      </FormGroup>
                      <Button size='small' component={RouterLink} to={`/invitation/${item.id}`}>Przejdź do ankiety</Button>
                    </Stack>
                  </CardActions>
                </Grid>
              </Grid>
            </Card>
          ))}
        </Stack>
      </div>
    )
  }
}
